<template>

  <div>
    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>회원정보</sub-title>
        <div class="main_panel">
          <div class="user-info">
            <span class="tk"><i class="fa fa-phone"></i> 휴대폰</span>
            <span class="t">{{ userInfo.phone }}</span>
            <span class="tk"><i class="fa fa-bank"></i> 은행명</span>
            <span class="t">{{ userInfo.bank }}</span>
            <span class="tk"><i class="fa fa-id-card"></i> 예금주</span>
            <span class="t">{{ userInfo.beneficiary }}</span>
            <span class="tk"><i class="fa fa-id-card"></i> 계좌번호</span>
            <span class="t">{{ userInfo.acNo }}</span>
            <span class="tk"><i class="fa fa-line-chart"></i> 레벨</span>
            <span class="t">{{ userInfo.rank.rankName }}</span>
            <!--                        <span class="tk"><i class="fa fa-money"></i> 최소베팅금</span>-->
            <!--                        <span class="t">{{userInfo.rank.betMinLimitCash|comma}}</span>-->
            <!--                        <span class="tk"><i class="fa fa-money"></i> 단폴최대베팅금</span>-->
            <!--                        <span class="t">{{userInfo.rank.betOneMaxLimitCash|comma}}</span>-->
            <!--                        <span class="tk"><i class="fa fa-money"></i> 최대베팅금</span>-->
            <!--                        <span class="t">{{userInfo.rank.betMaxLimitCash|comma}}</span>-->
            <!--                        <span class="tk"><i class="fa fa-money"></i> 최대당첨금</span>-->
            <!--                        <span class="t">{{userInfo.rank.winMaxLimitCash|comma}}</span>-->
            <span class="tk"><i class="fa fa-sign-in"></i> 로그인정보</span>
            <span class="t">{{ userInfo.lastLoginTime|datef('yyyy-MM-DD HH:mm') }}</span>
            <!--            <span class="tk">월급일</span>-->
            <!--            <span class="t">{{userInfo.salaryday}}일, {{userInfo.salarypercent}}%</span>-->
            <!--          <span class="tk">월급일</span>-->
            <!--          <span class="t">{{userInfo.salaryday}}일, {{userInfo.salarypercent}}%</span>-->
            <span class="tk"><i class="fa fa-registered"></i> 나의 추천코드:</span>
            <span class="t">{{ userInfo.referreCode.code }}</span>
            <span class="tk"><i class="fa fa-user-circle"></i> 나의 추천인</span>
            <span class="t">{{ userInfo.myReferrer != null ? userInfo.myReferrer.nickname : '' }}</span>
<!--            <span class="tk"><i class="fa fa-user-plus"></i> 추천회원</span>-->
<!--            <span class="t" style="padding: 0 5px">-->
<!--                <div class=""-->
<!--                     style="display: flex;justify-content:flex-start;align-items: start;flex-direction: column; font-size: 12px;margin-left: 2px;padding: 5px;"-->
<!--                     v-for="(item,index) in userInfo.subordinateUsers" :key="index">-->
<!--                     <i style="font-style: normal;">{{ item.nickname }}</i>-->
<!--                  &lt;!&ndash;                    <p class="mt2">입금:{{item.rechargeTotal|comma}}</p>&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <p class="mt2">출금:{{item.extractTotal|comma}}</p>&ndash;&gt;-->
<!--                </div>-->
<!--            </span>-->
            <!-- <span class="tk" style="width: 100%; margin: 10px 0;">
                   비밀번호변경
               </span>
             <span class="tk">낡은비밀번호</span>
             <span class="t">
                   <input type="password" v-model="user.oldPasswd">
               </span>
             <span class="tk">새비밀번호</span>
             <span class="t">
                   <input type="password" v-model="user.passwd">
               </span>
             <button @click="changePwd()" class="btn02"
                style="width: 50%;height:32px;margin: 10px 0">변경하기</button>-->


            <table class="utable" style="width: 100%;margin-top: 10px;">
              <tr>
                <th colspan="2" style="background-color: transparent">
                  포인트 내역
                </th>
              </tr>
              <tr>
                <th style="text-align: center; width: 15%;height:25px;">일자</th>
                <th style="text-align: center;width: 85%">내용</th>
              </tr>
              <tr v-for="(item,index) in referrerPointLogs" :key="index">
                <td style="border-bottom:1px solid #56565a;text-align: center;height: 28px"
                    height="28px;">
                  {{ item.createTime|datef('MM/DD HH:mm') }}
                </td>
                <td style="border-bottom:1px solid #56565a;padding-left: 10px;" align="left">
                  {{ item.content }}
                </td>
              </tr>
            </table>
            <!--페이지-->
            <pagination :page-index="pageNum"
                        :total="total"
                        :page-size="pageSize"
                        @change="pageChange"
                        v-if="referrerPointLogs.length > 0"></pagination>
          </div>
        </div>

      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>

  </div>

</template>

<script>
import RightBarComp from "../../components/RightBarComp";
import SubTitle from "../../components/SubTitle";
import {changePasswd, referrerPointLog} from "../../network/userRequest";
import LeftBarComp from "../../components/LeftBarComp";
import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
import Pagination from "../../components/pagenation/Pagination";
import FootComp from "../../components/FootComp";
import TopbarComp from "../../components/TopbarComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import sportsConst from "../../common/sportsConst";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
import {postionMixin} from "../../common/mixin";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

export default {
  name: "UserInfo",
  mixins: [postionMixin],
  components: {
    SportsBetCartComp,
    RightButtonsComp,
    UserInfoComp,
    SportsLeftBarComp,
    RightBarBannerComp,
    TopbarComp, FootComp, Pagination, LeisureLeftComp, LeftBarComp, SubTitle, RightBarComp
  },
  data() {
    return {
      referrerPointLogs: [],
      userInfo: {rank: {}, referreCode: {}, myReferrer: {}},
      user: {oldPasswd: '', passwd: '', extractPasswd: ''},
      pageNum: 1,
      pageSize: 10,
      total: 1,
      orderBy: null,
      search: {},
      sportsConst,
      position: "회원정보",
    }
  },
  methods: {
    changePwd() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changePasswd(this.user).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.$swal({
            title: '비밀번호 변경성공',
            type: 'success',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    getReferrerPointLog() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      referrerPointLog(this.pageNum, this.pageSize).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        this.userInfo = this.$store.state.userInfo
        if (res.data.success) {
          this.total = res.data.total
          this.referrerPointLogs = res.data.data
        }
      })
    },
    //pagination.vue 에서 페이지 클릭 시 emit발송접수 메소드
    pageChange(page) {
      this.pageNum = page
      this.getReferrerPointLog()
    },
  },
  created() {
    this.getReferrerPointLog()
  }
}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");

.user-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #666666;
  margin-top: 5px;
  padding: 5px;
}

.user-info span {
  display: inline-block;
  border-bottom: 1px solid #2d2d2d;
  font-size: 14px;
  line-height: 30px;
}

.user-info .tk {
  width: 30%;
  background-color: var(--noticeBg);
  text-align: left;
  color: #fff9ff;
  box-sizing: border-box;
  padding-left: 20px;
}

.user-info .t {
  width: 70%;
  background-color: var(--noticeBg);
  padding-left: 10px;
  box-sizing: border-box;
  color: #dedede;
}

.user-info input {
  background-color: #e0e0e0;
  height: 20px;
  color: #1c1c25;
  padding-left: 6px;
}

@media screen and (max-width: 800px) {
  input {
    width: 100%;
  }

  .user-info .tk {
    width: 40% !important;
  }

  .user-info .t {
    width: 60% !important;
  }
}

</style>